<template>
  <div class="wh-space-bottom">
    <template v-if="view_permission">
      <b-card v-if="is_admin">
        <table-search
          ref="search"
          v-model="filterValue"
          :fields="search_fields"
          :showBtn="false"
          @runSearch="runSearch"
          @changeField="searchChangeField"
        />
      </b-card>

      <b-card>
        <edit-field-header
          :title="title"
          :show-back-btn="false"
          :sub-title="$t('device_raw_data.warning_message')"
          sub-title-class="text-danger"
        />
        <edit-field
          :fields="fields"
          :rowData="rowData"
          post-api="/geofencing/DownloadRawData"
          finish-router="deviceRawData"
          :submitBtnText="$t('device_raw_data.export_data')"
          :submitAction="submitAction"
          @beforeSubmit="beforeSubmit"
        >
          <template #modalDevice="scope">
            <field-modal-selector
              ref="slot_device"
              :facility-id="scope.rowData.facilityID"
              :row-data="scope.rowData"
              :root-item="fields.find(field => field.name === 'deviceMACs')"
            />
          </template>
        </edit-field>
      </b-card>
    </template>
    <b-card v-else>{{ $t('permission.no_view') }}</b-card>

  </div>
</template>

<script>
import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ButtonPermission from '@/components/ButtonPermission'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import TableSearch from '@/components/TableSearch'
import FieldModalSelector from '@/components/FieldModalSelector'

export default {
  name: 'geoFencingRawDown',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,
    EditField,
    EditFieldHeader,
    TableSearch,
    FieldModalSelector,
  },
  data() {
    return {
      title: common.getMenuName('geoFencingRawDown'),
      view_permission: common.checkPermission('GeoFencing_Menu_RawDown'),
      // view_permission: true,
      is_admin: common.isAdmin(),
      company_options: [],
      facility_options: [],
      show_report_type: 'all',
      time_option: [],

      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId()
      },

      // 表單的默認信息
      rowData: {
        facilityID: common.getFacilityId(),
        deviceMACs: [],
      },

      fields: [
        {
          label: 'common.device',
          name: 'deviceMACs',
          type: 'slot',
          slot: 'modalDevice',
          rule: 'required',
          validate_in_slot: true,
          slot_config: {
            name: 'device',
            label: 'common.device',
            rule: 'required',
            list_api: '/geofencing/GetGeoFencings',
            list_name: 'geoFencings',
            item_tag_name: 'deviceMAC',
            item_id_name: 'geoFencingID',
            item_text_generator: item => `${item.deviceMAC} (${item.deviceName})`
          }
        },
        {
          label: 'device_report.start_date',
          name: 'startDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(-6, 'days')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        },
        {
          label: 'device_report.end_date',
          name: 'endDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(1, 'hours')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        }
      ]
    }
  },

  mounted() {
    // set default value from fields to forms
    this.fields.forEach((field) => {
      if (field.default !== undefined && field.name) {
        this.$set(this.rowData, field.name, field.default)
      }
    })
  },

  activated() {
    if (common.isAdmin()) {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
    }
  },

  methods: {
    handleDateChange({
      dateType,
      dateStr
    }) {
      const fields = this.fields
      const startDateIndex = fields.findIndex(field => field.name === 'startDate')
      const endDateIndex = fields.findIndex(field => field.name === 'endDate')
      common.syncFieldsOfDateRange({
        dayRange: 7,
        changedDateType: dateType,
        changedDateStr: dateStr,
        vmDateWrapperObj: this.rowData,
        vmStartDateConfig: startDateIndex >= 0 ? fields[startDateIndex].config : undefined,
        vmEndDateConfig: endDateIndex >= 0 ? fields[endDateIndex].config : undefined,
      })
    },

    beforeSubmit: function (callback) {
      const res_1 = this.$refs.slot_device ? this.$refs.slot_device.buildData() : true
      callback(res_1)
    },

    submitAction: function (rowData, api, _finishRouter) {
      // check date
      const startMoment = this.$moment(`${this.rowData['startDate']}`)
      const endMoment = this.$moment(`${this.rowData['endDate']}`)
      if (endMoment.diff(startMoment) < 0) {
        common.showError('ReportConditionDateError')
        return false
      }

      common.apiDownloadData({
        url: api,
        method: 'post',
        timeout: 2 * 60 * 60 * 1000,
        data: {
          startDate: rowData.startDate,
          endDate: rowData.endDate,
          facilityID: rowData.facilityID,
          deviceMACs: rowData.deviceMACs,
        },
      })
    },

    runSearch() {
    },

    async searchChangeField(field, value) {
      const idMap = await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
      this.rowData.facilityID = idMap.facilityID
      this.$refs.slot_device.clearItems()
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 2rem 0;
}

.reportWrapper:last-of-type > hr {
  display: none;
}
</style>

